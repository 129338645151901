import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuardService } from './services/auth.guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
      breadcrumb: ' '
    },
     canActivateChild: [AuthGuardService],
    children: [      
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule),

      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      //{
      //  path: 'dashboard',
      //  loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
      //  data: {
      //    title: 'Dashboard',
      //    breadcrumb: 'Dashboard'
      //  },
      //},
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'adminstration',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        //loadChildren: () => import('./views/Adminstration/adminstration.moudule').then(m => m.UserModule),
        canActivate: [AuthGuardService],
        data: {
          allowedRoles: ['SuperAdmin', "CompanyAdmin"],
          breadcrumb: 'Adminstration'
        }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuardService],
        data: {
          title: 'Dashboard',
          breadcrumb: 'Dashboard'
        },
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes,{useHash: false}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
