import { AfterViewInit, Component, OnInit, Renderer, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { WellModel } from '../../models/well.model';
import { DataTablesResponse } from "../../models/reponses/data-table.response";
import { AppInfoService } from '../../services/app.info.service';
import { AppUtilities } from '../../services/app.utilities';
import { DataTableParamtersModel } from '../../models/datatable-parameters.model';
import { Appkeys } from '../../services/app.keys';
import { CloudProcessService } from '../../services/cloudprocess.service';
import { CloudProcess } from '../../models/cloudprocess.model';
import { CloudProcessServiceModel } from '../../models/cloudProcessService.model';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Observable, timer, interval, Subscription } from 'rxjs';
import { switchMap, takeUntil, catchError } from 'rxjs/operators';
import { CloudProcessRequest } from '../../models/CloudProcessrequest.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TosterService } from '../../services/toster.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { ConfirmModalComponent } from '../Confimation-Dialog/ConfimationDialogmodal.component'
import { AuthService } from '../../services/auth.service';
import { SiteService } from '../../services/site.service';
import { SiteModel } from '../../models/site.model';

@Component({
  selector: 'zero-config',
  templateUrl: 'drillSurvey-Process.Component.html'
})
export class DrillProcessComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  @ViewChild('template', { static: false })
  private template: TemplateRef<any>;

  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  subscription: Subscription;
  token: string;
  listCloudIds = [];
  listofCloudHeadId: string;
  dtTrigger: Subject<any> = new Subject();
  isChecked = true;
  cloudProcessService: CloudProcessServiceModel[];
  baseUrl: string;
  wellsdddp = [];
  items: Array<any>;
  Geonomics: Array<any>;
  WellGeonomics: Array<any>;
  role: string;
  sites: Array<SiteModel>;
  siteId: number;

  cloudModel = {
    WellId: 0,
    DrillSurvayId: 0,
    WellGeonomicId: 0,
    CloudHeadId: null,
    PerfectCloudStatus: 0,
    ActualCloudStatus: 0,
    PlyFileStatus: 0,
    Description: "",
    resolution: 0
  }

  cloudModelrequest = {
    cloudprocessId: 0,
    fromlenth: 0,
    toLength: 0,
    CloudModelDescription: ""
  }
  valuetoprocess: number;
  IsAutomaticCall:boolean;

  constructor(private renderer: Renderer, private router: Router, private http: HttpClient, appInfo: AppInfoService,
    private CloudProcessService: CloudProcessService, private ngxService: NgxUiLoaderService, private toastr: TosterService, private modalService: BsModalService, private authservice: AuthService, private siteservice: SiteService) {
    this.baseUrl = appInfo.apiBaseUrl;
  }



  ngOnInit(): void {


    const that = this;
    this.siteId = -1;

    this.role = this.authservice.getCurrentUserRole();
    this.LoadGridview();    
    this.loadSiteDetails();
    this.load();
    // const source = interval(10000);
    // this.subscription = source.subscribe(val => this.rerender());
  }


  loadSiteDetails() {
    (this.role == "SuperAdmin" ? this.siteservice.LoadAllSiteDetails() : this.siteservice.LoadAllSiteDetailsByCompanyId(this.authservice.getCurrentUserCompanyId())).subscribe(result => {
      if (result != null && result.error === null) {
        this.sites = result.entities;
        console.log("Loading Site");
        console.log(this.sites);
      } else {
        this.toastr.ShowError("Error Occured", "Site");
      }
    });
  }

  loadWellDetails(id: number) {
    this.CloudProcessService.GetAllWellBySiteId(id).subscribe(result => {
      if (result != null && result.entities != null) {
        //this.setValuestoFrom(result.entity)
        this.items = result.entities
      }
    });
  }


  openModalWithClass(template: TemplateRef<any>, id: number) {

    this.listCloudIds = [];
    var cloudSelect = id;
    if (cloudSelect > 0) {
      this.listCloudIds.push(cloudSelect);
      this.modalRef = this.modalService.show(
        template,
        Object.assign({}, { class: 'gray modal-lg' })
      );
    } else {
      this.toastr.ShowInfo("Please Select the Colud", "Cloud Process");
    }
  }


  test(): void {

  }


  LoadGridview() {

    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      searching: false,
      responsive: true,
      retrieve: true,
     // displayStart: parseInt(localStorage.getItem("offset")),
      ajax: (dataTablesParameters: any, callback) => {
  
        var token = localStorage.getItem(Appkeys.ACCESS_TOKEN);
        //DirllSurvay/GetAllCloudProcessBySite?offset=1&limit=100&sortCol=Id&sortDirection=Ascending&siteId=1
        //this.http.get<DataTablesResponse<CloudProcessServiceModel>>(this.baseUrl + '/DirllSurvay/GetALlCloudProcess' + this.SetParmeters(dataTablesParameters), { headers: AppUtilities.getHttpHeadersApplicationJson(token) }).subscribe(resp => {

        this.http.get<DataTablesResponse<CloudProcessServiceModel>>(this.baseUrl + '/DirllSurvay/GetAllCloudProcessBySite' + this.SetParmeters(dataTablesParameters) + '&siteId=' + this.siteId, { headers: AppUtilities.getHttpHeadersApplicationJson(token) }).subscribe(resp => {
          this.cloudProcessService = resp.entities;       
          callback({
            recordsTotal: resp.numberofFilterdEntities,
            recordsFiltered: resp.numberofEntities,
            data: resp.entities,
          });
        });
      },
      columnDefs: [
        { className: 'text-center', targets: [0, 7] },
      ],
      columns: [
      
        { data: 'wellName' },
        { data: 'description' },
        // { data: 'lasFileFileName' },
        { data: 'fromLength' },
        { data: 'toLength' },
        { data: 'perfectVolume' },
        { data: 'actualVolume' },
        { data: 'diffVolume' },
        //{ data: 'cloudHeadId' },
        { data: 'timeTakenToProcessModel' },

        {
          render: function (data: any, type: any, full: any) {
            if (full.plyFileStatus != null && full.plyFileStatus == 0)
              return '<span > <i class="fa fa-cogs dt-action-btn colorLG" aria-hidden="true"></i></span>';

            else if (full.plyFileStatus != null && full.plyFileStatus == 1)
              return '<span > <i class="fa fa-cogs dt-action-btn colorLG" aria-hidden="true"></i></span>';

            else if (full.plyFileStatus != null && full.plyFileStatus == 2)
              return '<span > <i class="fa fa-check dt-action-btn colorLG" aria-hidden="true"></i></span>';
          }
        },

        {
          render: function (data: any, type: any, full: any) {
            // return '<i class="fa fa-fw fa-trash text-danger dt-action-btn" aria-hidden="true" delete-cloudProcess-id=' + full.id + '></i>'
            return '<button class="btn btn-md btn-primary mr-1" type="button" type="button" aria-hidden="true" open-drillsurvey-id=' + full.id + '  (click)="OpenDrillSurvey()">View Drill Path</button>'
          }
        },
        {
          render: function (data: any, type: any, full: any) {
            return '<button class="btn btn-md btn-primary" type="button" open-downloadpopup-id=' + full.id + '></i>Open Model</button>'
          }
        },

        {
          render: function (data: any, type: any, full: any) {
            return '<i class="fa fa-fw fa-trash text-danger dt-action-btn" aria-hidden="true" delete-cloudProcess-id=' + full.id + '></i>'
          }
        },


      ]
      //,
      //rowCallback: (row: Node, data: any[] | Object, index: number) => {
      //  // const self = this;
      //  // $('td', row).unbind('click');
      //  // // $('.chselect').unbind('click');
      //  // // $('.chselect').bind('click', () => {
      //  // $('td', row).bind('click', () => {


      //  //   $('td').each(function () {
      //  //     $(this).removeClass('selectedRow');
      //  //   });

      //  //   $(".chselect").each(function () {
      //  //     $(this).prop('checked', false);
      //  //   });

      //  //   $('input[type="checkbox"]', row).prop('checked', true)
      //  //   $('td', row).addClass('selectedRow');

      //  // if ($('input[type="checkbox"]', row).is(":checked")) {
      //  //   alert('dd')
      //  //   $('input[type="checkbox"]', row).prop('checked', true)
      //  //   $('td', row).css('background-color', '#8CA084');
      //  // }
      //  //   else {
      //  //     alert('false')
      //  //     $('input[type="checkbox"]', row).prop('checked', false);
      //  //     $('td', row).css('background-color', '');
      //  //   }

      //  // });
      //  // return row;
      //},


    };
  }




  LoadSelectBoxes(id: number) {

    this.CloudProcessService.LoadDrillSurveyGeonomics(id).subscribe(result => {
      if (result != null && result.entities != null) {
        this.Geonomics = result.entities
      }
    });

    this.CloudProcessService.LoadGLassFileGenomics(id).subscribe(result => {
      if (result != null && result.entities != null) {
        this.WellGeonomics = result.entities
      }
    });

  }


  changeValueWellSelect(event) {

    if (event.target.value != null) {
      this.LoadSelectBoxes(event.target.value)
    } else
    {
      this.Geonomics = null;
    }

  }

  changeValueSiteSelect(event) {

    if (event.target.value != null) {
      this.siteId = event.target.value;
      this.loadWellDetails(event.target.value);
    }
    else {
      this.siteId = -1;
    }

    this.Geonomics = null;
    this.WellGeonomics = null;
    $('#gridView').DataTable().ajax.reload();


  }


  SetParmeters(datatableParameters): string {

    console.log(datatableParameters.start)

   // var offset=parseInt(datatableParameters.start);
   // console.log(datatableParameters.draw)
   
    let header: Array<string> = ['WellName', 'DrillSurveyFileName', 'LasFileName', 'CloudHeadId', 'PerfectCloudStatus', 'ActualCloudStatus', 'PlyFileStatus', 'Description'];
    // if(datatableParameters.start==0 && this.IsAutomaticCall==true){
    // var offset=parseInt(localStorage.getItem("offset"));
    // this.IsAutomaticCall=false
    // }
    // else{
    //  var start=datatableParameters.start
    // localStorage.setItem("offset",start);
    //  var offset=parseInt(start);
    
    // }

   var offset=datatableParameters.start

   
  
    const parameterModel = new DataTableParamtersModel();
    parameterModel.offset = datatableParameters.start != undefined && datatableParameters.start != null ? datatableParameters.start : 0;
    parameterModel.limit = datatableParameters.length != undefined && datatableParameters.length != null ? datatableParameters.length : 100;
    parameterModel.sortCol = datatableParameters.order.length > 0 ? header[datatableParameters.order[0].column] : "Id";
    parameterModel.sortDirection = datatableParameters.order.length > 0 && datatableParameters.order[0].dir == "asc" ? "Ascending" : "Descending";
    parameterModel.searchText = datatableParameters.search.value;


    return '?offset=' + parameterModel.offset + '&limit=' + parameterModel.limit + '&sortCol=' + parameterModel.sortCol + '&sortDirection='
      + parameterModel.sortDirection + "&searchText=" + parameterModel.searchText;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.renderer.listenGlobal('document', 'click', (event) => {

      if (event.target.hasAttribute("view-well-id")) {
        let wellid = event.target.getAttribute("view-well-id");
        // this.DeleteOfWell(wellid);
      } else if (event.target.hasAttribute("delete-cloudProcess-id")) {
        let wellid = event.target.getAttribute("delete-cloudProcess-id");

        this.modalRef = this.modalService.show(ConfirmModalComponent, {
          initialState: {
            prompt: 'Do you want to delete the 3D Model?"',
            header: '3D Model',
            callback: (result) => {
              console.log(result)
              if (result == 'yes') {
                this.DeleteOfWell(wellid);
              } else if (result == 'no') {

              }
            }
          }
        });


        // } else if (event.target.hasAttribute("checkid-well-id")) {
        //   let add = event.target.getAttribute("checkid-well-id");
        //   let wellId = event.target.getAttribute("checkid-well-id");

        //   let headerId = event.target.getAttribute("cloud-heder-id");
        //   event.target.setAttribute("checked", "checked");
        //   this.listCloudIds = [];
        //   this.listofCloudHeadId = "";
        //   this.listCloudIds.push(add)
        //   this.listofCloudHeadId = headerId;

        //   this.CloudProcessService.GetCloudProcessFromId(this.listCloudIds[0]).subscribe(result => {

        //     this.cloudModelrequest.CloudModelDescription = result.entity.description
        //     this.cloudModelrequest.fromlenth = result.entity.fromLength
        //     this.cloudModelrequest.toLength = result.entity.toLength;

        //     this.cloudModel.WellId = result.entity.wellId;
        //     this.CloudProcessService.LoadDrillSurveyGeonomics(this.cloudModel.WellId).subscribe(result1 => {
        //       if (result1 != null && result1.entities != null) {
        //         this.Geonomics = result1.entities
        //         this.cloudModel.DrillSurvayId = result.entity.drillSurveyHead.genomicFileId;         
        //       }
        //     });

        //     this.CloudProcessService.LoadGLassFileGenomics(this.cloudModel.WellId).subscribe(result2 => {
        //       if (result != null && result2.entities != null) {
        //         this.WellGeonomics = result2.entities
        //         // console.log(this.WellGeonomics )
        //         this.cloudModel.WellGeonomicId = result.entity.wellGeonomicFile.geonomicFileId;

        //       }
        //     });

        //   });
      } else if (event.target.hasAttribute("open-drillsurvey-id")) {
        let id = event.target.getAttribute("open-drillsurvey-id");
        this.OpenDrillSurvey(id);


      } else if (event.target.hasAttribute("open-downloadpopup-id")) {
        let id = event.target.getAttribute("open-downloadpopup-id");

        this.openModalWithClass(this.template, id);

      }
    });
    
  }

  checkBack(wellobjectid: any) {
    //alert(e.target.checked);
    //alert(valueid);
  }


  DeleteOfWell(cloudproccessid: number): void {

    let cloudprocessDetails: CloudProcess = {
      Id: cloudproccessid,
      WellId: 0,
      DrillSurvayId: 0,
      WellGeonomicId: 0,
      CloudHeadId: 0,
      PerfectCloudStatus: 0,
      ActualCloudStatus: 0,
      PlyFileStatus: 0,
      Description: "",
      FromLength: 0,
      ToLength: 0
    };

    this.CloudProcessService.DeleteCloudProcess(cloudprocessDetails).subscribe(result => {
      if (result.error != null) {

        this.ngxService.stop();
        this.toastr.ShowInfo("Error On Deleting the Well", "Cloud Process");
        this.listCloudIds = [];
        this.listofCloudHeadId = "";

      } else {

        if (result != null && result.entity != null) {
          this.rerender1();
          this.toastr.showSuccess("Cloud Process Deleted Successfully", "Cloud Process");
          this.ngxService.stop();
          this.listCloudIds = [];
          this.listofCloudHeadId = "";
        }
        else {
          this.ngxService.stop();
          this.listCloudIds = [];
          this.listofCloudHeadId = "";
        }
      }

    });


  }

  navWellRegister(): void {
    //this.router.navigate(["/adminstration/wellregister/"]);
    this.ngxService.start();
    if (this.cloudModel.WellId != 0 && this.cloudModel.DrillSurvayId != 0 && this.cloudModel.WellGeonomicId != 0) {

      let cloudprocessDetails: CloudProcess = {
        Id: 0,
        WellId: this.cloudModel.WellId,
        DrillSurvayId: this.cloudModel.DrillSurvayId,
        WellGeonomicId: this.cloudModel.WellGeonomicId,
        CloudHeadId: this.cloudModel.CloudHeadId,
        PerfectCloudStatus: this.cloudModel.PerfectCloudStatus,
        ActualCloudStatus: this.cloudModel.ActualCloudStatus,
        PlyFileStatus: this.cloudModel.PlyFileStatus,
        Description: this.cloudModel.Description,
        FromLength: 0,
        ToLength: 0
      };

      this.CloudProcessService.SavetheCloudProcess(cloudprocessDetails).subscribe(result => {

        if (result.error != null) {

          this.ngxService.stop();
          this.toastr.ShowInfo("Cloud Process job already Exsists", "Cloud Process");
          this.listCloudIds = [];
          this.listofCloudHeadId = "";

        } else {
          if (result != null && result.entity != null) {
            this.rerender1();
            this.toastr.showSuccess("Cloud Process Job Saved Successfully Check the Folder", "Cloud Process");
            this.ngxService.stop();
            this.listCloudIds = [];
            this.listofCloudHeadId = "";
          }
          else {
            this.ngxService.stop();
            this.listCloudIds = [];
            this.listofCloudHeadId = "";
          }
        }


      });
    }
  }


  OpenDrillSurvey(id): void {

    if (id > 0) {
      // this.router.navigate(['/adminstration/survayplot?id='+this.listCloudIds[0]).then( (e) => {
      window.open('#/adminstration/survayplot?id=' + id, '_blank');
      // });
    } else {
      this.toastr.ShowInfo("Please select cloud Process", "Cloud Process");
    }
  }


  Open3DCloud(cloudtype: string): void {
    this.token = localStorage.getItem(Appkeys.ACCESS_TOKEN);
    this.ngxService.start();
    if (this.listCloudIds.length > 0) {
      let fileName1: string = "";
      var subject = new Subject<any>();
      subject.next(null);
      this.http.get(this.baseUrl + '/DirllSurvay/DownloadPlyFile?fileId=' + this.listCloudIds[0] + "&" + "cloudType=" + cloudtype, { responseType: 'blob', headers: AppUtilities.getHttpHeadersApplicationJson(this.token) }).subscribe(result => {
        console.log(result)
        const url = window.URL.createObjectURL(result);
        if (cloudtype == "A") {
          fileName1 = "ActualCloud_" + this.listCloudIds[0] + ".ply";
        }
        else if (cloudtype == "P") {
          fileName1 = "PerfectCloud_" + this.listCloudIds[0] + ".ply";
        }
        const objectUrl: string = URL.createObjectURL(result);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName1;
        document.body.appendChild(a);
        a.click();

        // window.open(url);

        return subject.next(result);
      },
        error => {
          return subject.next(error);
        }
      );

      this.ngxService.stop();
    } else {
      this.ngxService.stop();
      this.toastr.ShowInfo("Please select cloud Process", "Cloud Process");
    }
  }

  CloudProcessExecute() {

    //console.log(this.cloudModelrequest);
    //console.log((this.cloudModelrequest.toLength - this.cloudModelrequest.fromlenth) >= 0);

    // if (this.listCloudIds.length > 0) {
    /// if (this.listofCloudHeadId != "" && this.listofCloudHeadId != null && confirm("Do you want to overwite the Cloud Proccess")) {
    if (this.cloudModelrequest.fromlenth != null && this.cloudModelrequest.toLength != null
      && this.cloudModel.WellId != 0 && this.cloudModel.DrillSurvayId != 0 && this.cloudModel.WellGeonomicId != 0) {

      if ((this.cloudModelrequest.toLength - this.cloudModelrequest.fromlenth)>=0) {
        
        let cloudprocessId = this.listCloudIds[0];
        let cloudprocessReqest: CloudProcess = {

          WellGeonomicId: this.cloudModel.WellGeonomicId,
          DrillSurvayId: this.cloudModel.DrillSurvayId,
          WellId: this.cloudModel.WellId,
          FromLength: this.cloudModelrequest.fromlenth,
          ToLength: this.cloudModelrequest.toLength,
          Description: this.cloudModelrequest.CloudModelDescription,
          CloudHeadId: 0,
          PerfectCloudStatus: 0,
          ActualCloudStatus: 0,
          PlyFileStatus: 0,
          Id: 0

        };
        this.toastr.ShowInfo("Process Started", "Cloud Process");
        //this.rerender();
        
        this.CloudProcessService.GetCloudProcessId(cloudprocessReqest).subscribe(result => {
          // this.ngxService.stop();
          this.rerender1();
          this.toastr.ShowInfo("Process was Finished Please check the Status of table", "Cloud Process");

        });
        this.rerender1();
      
      } else {
        this.toastr.ShowInfo("From Length should less than To lenght", "Cloud Process");
        
      }
    } else {
      // this.ngxService.stop();
      this.toastr.ShowInfo("Please Choose Well ,Drill Survay, Las File ", "Cloud Process");

    }
    // } else {
    //   this.ngxService.stop();
    // }



    // } else {
    //this.ngxService.stop();
    //  this.toastr.ShowInfo("Please select cloud Process", "Cloud Process");
    // }



  }



  GenerateSteppingData() {
    console.log(this.cloudModel.DrillSurvayId);

    console.log(this.cloudModel.resolution);

    if (this.cloudModel.DrillSurvayId != 0) {

      if (this.cloudModel.resolution != null && this.cloudModel.resolution != 0) {

        //this.rerender();
        this.ngxService.start();
        this.CloudProcessService.GenerateSteppingData(this.cloudModel.DrillSurvayId, this.cloudModel.resolution).subscribe(result => {
          this.ngxService.stop();

          if (result != null && result >= 0) {
            this.cloudModel.resolution = 0;
            if (this.cloudModel.WellId) {
              this.LoadSelectBoxes(this.cloudModel.WellId);
            }
            this.toastr.ShowInfo("Process was Finished Please check Drill Survey Selection", "Step Data Process");

          }
          else {
            this.toastr.ShowError("Process was Failed Please try again", "Step Data Process");

          }
          
          //this.rerender1();

        });
        //this.rerender1();


      } else {
        this.ngxService.stop();
        this.toastr.ShowInfo("Please Enter Resolution", "Step Data Process");

      }
    }
    else
    {
      // this.ngxService.stop();
      this.toastr.ShowInfo("Please Choose Well ,Drill Survay", "Step Data  Process");

    }
  }

   load(){
    const source = interval(10000);
    
  
    this.subscription = source.subscribe(val => this.rerender1()  );
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.LoadGridview() 
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      this.IsAutomaticCall=true
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }


  rerender1(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
     // dtInstance.destroy();

     this.IsAutomaticCall=true
     dtInstance.ajax.reload(null,false);
    
    
    });
  }


}
