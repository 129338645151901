import { Injectable } from '@angular/core';
import { AppUtilities } from './app.utilities';
import { Appkeys } from './app.keys';



@Injectable({ providedIn: 'root' })
export class AppInfoService {
  constructor() {}

  public get title() {
    return 'Genomic';
  }

  public get apiBaseUrl() {
    
    //return 'http://localhost:56796/api' //DEV
    //return 'http://apitestgeonomictech.ipidata.com/api'
    return 'https://apitestgeonomictech.ipidata.com/api'//UAT
    //return 'https://apitest.geonomictech.com/api' //UAT
    //return 'https://api.geonomictech.com/api' //PROD

    //return 'http://localhost:8081/api'

  //  return 'http://geoapi.secureflow.com/api';
  }

  public get loginUserName() {
    return localStorage.getItem(Appkeys.CURRENT_USER);
  }

}
